<template>
  <div id="solutionCard">
    <!-- Solution Dialog -->
    <v-dialog v-model="solutionDialog" scrollable max-width="776px">
      <v-card :loading="superLoading || superLoading" v-if="selectedQuestionID">
        <v-card-title class="d-flex justify-space-between align-center">
          <span>
            <v-icon class="mr-2" color="deep-purple darken-4">
              mdi-clipboard-check
            </v-icon>

            Solution :
          </span>

          <span v-if="testLang" class="mt-3 mt-md-0 mb-n6">
            <v-select
              id="langSelect"
              label="Language"
              v-model="testLang"
              :items="testLangs"
              outlined
              dense
            ></v-select>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 60vh" class="pa-2">
          <!-- English Solution -->
          <span
            v-if="
              testLang == 'en' || !allQuestions[selectedQuestionID].isBilingual
            "
            class="htmlFromEditor"
            v-html="allQuestions[selectedQuestionID].solution.Primary"
          ></span>

          <!-- Hindi Solution -->
          <span
            v-else
            class="htmlFromEditor"
            v-html="allQuestions[selectedQuestionID].solution.Secondary"
          ></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="solutionDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Actual Display Card -->
    <v-card :loading="superLoading || superLoading">
      <v-card-title class="d-flex justify-space-between align-center">
        <span>
          <v-icon class="mr-2" color="deep-purple darken-4">
            mdi-text-box-check
          </v-icon>

          Solution :
        </span>

        <span v-if="testLang" class="mt-3 mt-md-0 mb-n6">
          <v-select
            id="langSelect"
            label="Language"
            v-model="testLang"
            :items="testLangs"
            outlined
            dense
          ></v-select>
        </span>
      </v-card-title>

      <v-card-text v-if="testLang" class="px-1 px-md-3">
        <v-container fluid v-if="!loading || !superLoading">
          <v-row align="center" justify="center">
            <v-expansion-panels focusable>
              <!-- Sections Loop -->
              <template
                v-for="(questionsArr, sectionName) in testObj.selectedQuestions"
              >
                <v-expansion-panel :key="sectionName">
                  <!-- Header -->
                  <v-expansion-panel-header class="text-center text-button">
                    <b>{{ sectionName }}</b>
                  </v-expansion-panel-header>

                  <!-- Content -->
                  <v-expansion-panel-content class="px-0">
                    <v-container fluid class="px-0 px-md-3">
                      <v-row align="center" justify="center">
                        <!-- Questions for that section loop -->
                        <template v-for="(questionID, i) in questionsArr">
                          <v-col
                            cols="12"
                            sm="12"
                            class="px-0 px-md-3"
                            :key="questionID + '_' + sectionName"
                          >
                            <!-- Question Number and Show Solution Button -->
                            <div class="d-flex justify-space-between">
                              <v-chip label small>
                                Question {{ i + 1 }} :</v-chip
                              >
                              <v-btn
                                @click.prevent="showSolution(questionID)"
                                small
                              >
                                <v-icon size="18" class="mr-2" color="primary"
                                  >mdi-clipboard-check</v-icon
                                >
                                Show Solution
                              </v-btn>
                            </div>

                            <!-- Question -->
                            <div class="mt-3 ml-1 ml-md-3">
                              <!-- English Question -->
                              <span
                                v-if="
                                  testLang == 'en' ||
                                  !allQuestions[questionID].isBilingual
                                "
                                class="htmlFromEditor"
                                v-html="
                                  allQuestions[questionID].question.Primary
                                "
                              ></span>

                              <!-- Hindi Question -->
                              <span
                                v-else
                                class="htmlFromEditor"
                                v-html="
                                  allQuestions[questionID].question.Secondary
                                "
                              ></span>
                            </div>

                            <!-- Options -->
                            <div class="mt-4">
                              <v-radio-group
                                v-model="allQuestions[questionID].correctAnswer"
                                readonly
                              >
                                <v-container fluid>
                                  <!-- Option loop -->
                                  <v-row
                                    align="center"
                                    no-gutters
                                    v-for="(optionIndex, i) in allQuestions[
                                      questionID
                                    ].options"
                                    :key="i"
                                  >
                                    <v-col class="d-flex" cols="12" sm="12">
                                      <span class="mr-2 d-flex align-start">
                                        <v-radio
                                          on-icon="mdi-checkbox-marked-circle"
                                          off-icon="mdi-plus-circle mdi-rotate-45"
                                          :value="optionIndex"
                                        >
                                          <!-- Label -->
                                          <template slot="label">
                                            <!-- User Check Icon -->
                                            <span
                                              v-if="
                                                resultObj.selectedAnswers[
                                                  questionID
                                                ] == optionIndex
                                              "
                                            >
                                              <v-icon
                                                :color="
                                                  resultObj.selectedAnswers[
                                                    questionID
                                                  ] ==
                                                  allQuestions[questionID]
                                                    .correctAnswer
                                                    ? 'primary'
                                                    : 'red'
                                                "
                                                >mdi-account-check</v-icon
                                              >
                                            </span>

                                            <!-- Option Data -->
                                            <!-- Primary -->
                                            <span
                                              v-if="
                                                testLang == 'en' ||
                                                !allQuestions[questionID]
                                                  .isBilingual
                                              "
                                              class="
                                                mt-4
                                                ml-1 ml-md-3
                                                htmlFromEditor
                                              "
                                              v-html="
                                                allQuestions[questionID]
                                                  .optionsData[optionIndex]
                                                  .Primary
                                              "
                                            ></span>

                                            <!-- Secondary -->
                                            <span
                                              v-else
                                              class="
                                                mt-4
                                                ml-1 ml-md-3
                                                htmlFromEditor
                                              "
                                              v-html="
                                                allQuestions[questionID]
                                                  .optionsData[optionIndex]
                                                  .Secondary
                                              "
                                            ></span>
                                          </template>
                                        </v-radio>
                                      </span>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-radio-group>
                            </div>

                            <v-divider></v-divider>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SolutionCard",
  props: ["superLoading", "testObj", "resultObj", "allQuestions"],

  computed: {
    testLangs() {
      return this.testObj.isBilingual
        ? [
            {
              text: "English",
              value: "en",
            },
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : this.testObj.onlySecondaryLang
        ? [
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : [
            {
              text: "English",
              value: "en",
            },
          ];
    },
  },

  data: () => ({
    loading: false,
    sectionalData: null,
    testLang: "",
    solutionDialog: false,
    selectedQuestionID: "",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    showSolution(questionID) {
      this.selectedQuestionID = questionID;
      this.solutionDialog = true;
    },
  },

  mounted() {
    this.testLang = this.testLangs[0].value;
    this.loading = false;
  },
};
</script>

<style>
.v-application .primary--text {
  color: green !important;
}
</style>