<template>
  <v-card id="youVsTopperCard" :loading="superLoading || superLoading">
    <v-card-title>
      <v-icon
        class="mr-2"
        :color="isTopper ? 'amber darken-2' : 'deep-purple darken-4'"
      >
        {{ isTopper ? "mdi-trophy-award" : "mdi-file-compare" }}
      </v-icon>

      <span class="mx-auto mx-md-1">
        {{ isTopper ? "Congratulations!" : "You vs Topper :" }}
      </span>

      <!-- Result Toggle Button -->
      <span v-if="reAttempt" class="mx-auto mx-md-1 mt-3 mt-md-0">
        <v-btn-toggle v-model="resultView" class="ml-3" mandatory>
          <v-btn value="original" small> Original </v-btn>

          <v-btn value="reAttempt" small> Re-attempt </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>

    <v-card-text>
      <v-container
        fluid
        v-if="!loading && !superLoading && calcResultData != null"
      >
        <!-- Congratulations Card -->
        <template v-if="isTopper">
          <div>
            <v-img
              height="180"
              class="mx-auto hidden-sm-and-downs"
              src="../../../assets/home/test/undraw_winners_ao2o.svg"
              contain
            ></v-img>
          </div>

          <div class="mt-9 text-h5 text-center">
            Congratulations! <br />
            You are the lead ranker.
          </div>

          <div class="text-center text-subtitle-2 mt-5">
            Time Taken :
            {{
              resultView == "original"
                ? calcResultData.timeTakenText
                : calcReAttemptResultData.timeTakenText
            }}
          </div>

          <div class="mt-5 text-subtitle-2 text-center px-1 px-md-5">
            Keep up the good work.<br />
            <i>
              Do note that your ranking might change with more people taking the
              test.
            </i>
          </div>
        </template>

        <!-- You vs Topper Table -->
        <template v-else>
          <v-simple-table
            v-if="
              calcResultData != null &&
              testObj != null &&
              calcTopperResultData != null
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center"></th>
                  <th class="text-center">You</th>
                  <th class="text-center">Topper</th>
                </tr>
              </thead>
              <tbody>
                <!-- Score -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-sort-numeric-variant
                    </v-icon>
                  </td>

                  <td class="text-center">Score</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.scoreText
                        : calcReAttemptResultData.scoreText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.scoreText }}
                  </td>
                </tr>

                <!-- Time Taken -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-timer-sand
                    </v-icon>
                  </td>

                  <td class="text-center">Time Taken</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.timeTakenText
                        : calcReAttemptResultData.timeTakenText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.timeTakenText }}
                  </td>
                </tr>

                <!-- Attempted -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-spellcheck
                    </v-icon>
                  </td>

                  <td class="text-center">Attempted</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.attemptedText
                        : calcReAttemptResultData.attemptedText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.attemptedText }}
                  </td>
                </tr>

                <!-- Correct -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-check-circle-outline
                    </v-icon>
                  </td>

                  <td class="text-center">Correct</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.correctText
                        : calcReAttemptResultData.correctText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.correctText }}
                  </td>
                </tr>

                <!-- Incorrect -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-close-circle-outline
                    </v-icon>
                  </td>

                  <td class="text-center">Incorrect</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.incorrectText
                        : calcReAttemptResultData.incorrectText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.incorrectText }}
                  </td>
                </tr>

                <!-- Accuracy -->
                <tr>
                  <td class="text-center">
                    <v-icon color="blue darken-2" size="25">
                      mdi-bullseye-arrow
                    </v-icon>
                  </td>

                  <td class="text-center">Accuracy</td>

                  <td class="text-center">
                    {{
                      resultView == "original"
                        ? calcResultData.accuracyText
                        : calcReAttemptResultData.accuracyText
                    }}
                  </td>

                  <td class="text-center">
                    {{ calcTopperResultData.accuracyText }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
var dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "YouVsTopperCard",
  props: [
    "superLoading",
    "testObj",
    "resultObj",
    "allQuestions",
    "topperResultObj",
    "isTopper",
    "reAttempt",
    "reAttemptResultObj",
  ],
  data: () => ({
    loading: false,
    calcResultData: null,
    calcReAttemptResultData: null,
    calcTopperResultData: null,
    resultView: "original",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    // totalTestDuration to hhmmss
    calcAndFormatTestDuration(duration) {
      var raw_duration = duration / 60;

      raw_duration = Math.round(raw_duration * 1000) / 1000; //round off to 3 decimal places

      var hours = Math.floor(raw_duration);
      //calc and round off to 2 decimal places
      const minutes_raw = Math.round((raw_duration % 1) * 60 * 100) / 100;
      var minutes = Math.floor(minutes_raw);
      var seconds = Math.round((minutes_raw % 1) * 60);

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      // return `hh:mm:ss`
      return `${hours}:${minutes}:${seconds}`;
    },

    calculateData() {
      var calcResultData = {};
      var calcTopperResultData = {};

      if (Object.keys(this.topperResultObj).length < 1) {
        return;
      }

      // Score Text
      const maxScore = this.testObj.questionCount * this.testObj.rewardPoints;

      calcResultData.scoreText = `${this.resultObj.score}/${maxScore}`;
      calcTopperResultData.scoreText = `${this.topperResultObj.score}/${maxScore}`;

      //Accuracy, attempted, Correct and Incorrect Text
      var attempted = 0;
      var correct = 0;

      // Student
      Object.keys(this.resultObj.questionState).forEach((questionID) => {
        if (this.resultObj.questionState[questionID] == "answered") {
          attempted++;
          if (
            this.resultObj.selectedAnswers[questionID] ==
            this.allQuestions[questionID].correctAnswer
          ) {
            correct++;
          }
        }
      });

      calcResultData.correctText = `${correct}/${attempted}`;
      calcResultData.incorrectText = `${attempted - correct}/${attempted}`;
      calcResultData.attemptedText = `${attempted}/${this.testObj.questionCount}`;

      var accuracy = (correct / attempted) * 100;
      calcResultData.accuracyText = isNaN(accuracy)
        ? "0%"
        : `${accuracy.toFixed(2)}%`;

      // reset counter variables
      attempted = 0;
      correct = 0;
      accuracy = 0;

      // Topper
      Object.keys(this.topperResultObj.questionState).forEach((questionID) => {
        if (this.topperResultObj.questionState[questionID] == "answered") {
          attempted++;
          if (
            this.topperResultObj.selectedAnswers[questionID] ==
            this.allQuestions[questionID].correctAnswer
          ) {
            correct++;
          }
        }
      });

      calcTopperResultData.correctText = `${correct}/${attempted}`;

      calcTopperResultData.incorrectText = `${
        attempted - correct
      }/${attempted}`;

      calcTopperResultData.attemptedText = `${attempted}/${this.testObj.questionCount}`;

      accuracy = (correct / attempted) * 100;
      calcTopperResultData.accuracyText = isNaN(accuracy)
        ? "0%"
        : `${accuracy.toFixed(2)}%`;

      // Time Taken

      // Student
      if (this.resultObj.timeLeft != null) {
        const timeLeft = dayjs(this.resultObj.timeLeft, "HH:mm:ss");

        var totalTestDuration_hhmmss = `00:${this.testObj.testDuration}:00`;

        if (this.testObj.testDuration >= 60) {
          totalTestDuration_hhmmss = this.calcAndFormatTestDuration(
            this.testObj.testDuration
          );
        }

        const totalTestDuration = dayjs(totalTestDuration_hhmmss, `{HH:mm:ss}`);

        var timeTaken = totalTestDuration.diff(timeLeft); //reurns milli seconds

        timeTaken = new Date(timeTaken).toISOString().substr(11, 8);

        calcResultData.timeTakenText = `${timeTaken}`;
      } else {
        var timeTakenFull = new Date(this.testObj.testDuration * 60 * 1000)
          .toISOString()
          .substr(11, 8);

        calcResultData.timeTakenText = `${timeTakenFull}`;
      }

      timeTaken = null;
      timeTakenFull = null;

      // Topper
      if (this.topperResultObj.timeLeft != null) {
        const timeLeft = dayjs(this.topperResultObj.timeLeft, "HH:mm:ss");

        var totalTestDuration_hhmmss_topper = `00:${this.testObj.testDuration}:00`;

        if (this.testObj.testDuration >= 60) {
          totalTestDuration_hhmmss_topper = this.calcAndFormatTestDuration(
            this.testObj.testDuration
          );
        }

        const totalTestDuration = dayjs(
          totalTestDuration_hhmmss_topper,
          `{HH:mm:ss}`
        );

        timeTaken = totalTestDuration.diff(timeLeft); //reurns milli seconds

        timeTaken = new Date(timeTaken).toISOString().substr(11, 8);

        calcTopperResultData.timeTakenText = `${timeTaken}`;
      } else {
        timeTakenFull = new Date(this.testObj.testDuration * 60 * 1000)
          .toISOString()
          .substr(11, 8);

        calcTopperResultData.timeTakenText = `${timeTakenFull}`;
      }

      this.calcResultData = calcResultData;
      this.calcTopperResultData = calcTopperResultData;
    },

    calculateReAttemptData() {
      var calcResultData = {};

      if (Object.keys(this.reAttemptResultObj).length < 1) {
        return;
      }

      // Score Text
      const maxScore = this.testObj.questionCount * this.testObj.rewardPoints;

      calcResultData.scoreText = `${this.reAttemptResultObj.score}/${maxScore}`;

      //Accuracy, attempted, Correct and Incorrect Text
      var attempted = 0;
      var correct = 0;

      // Student
      Object.keys(this.reAttemptResultObj.questionState).forEach(
        (questionID) => {
          if (this.reAttemptResultObj.questionState[questionID] == "answered") {
            attempted++;
            if (
              this.reAttemptResultObj.selectedAnswers[questionID] ==
              this.allQuestions[questionID].correctAnswer
            ) {
              correct++;
            }
          }
        }
      );

      calcResultData.correctText = `${correct}/${attempted}`;
      calcResultData.incorrectText = `${attempted - correct}/${attempted}`;
      calcResultData.attemptedText = `${attempted}/${this.testObj.questionCount}`;

      var accuracy = (correct / attempted) * 100;
      calcResultData.accuracyText = isNaN(accuracy)
        ? "0%"
        : `${accuracy.toFixed(2)}%`;

      // Time Taken

      // Student
      if (this.reAttemptResultObj.timeLeft != null) {
        const timeLeft = dayjs(this.reAttemptResultObj.timeLeft, "HH:mm:ss");

        const totalTestDuration = dayjs(
          `00:${this.testObj.testDuration}:00`,
          `{HH:mm:ss}`
        );

        var timeTaken = totalTestDuration.diff(timeLeft); //reurns milli seconds

        timeTaken = new Date(timeTaken).toISOString().substr(11, 8);

        calcResultData.timeTakenText = `${timeTaken}`;
      } else {
        var timeTakenFull = new Date(this.testObj.testDuration * 60 * 1000)
          .toISOString()
          .substr(11, 8);

        calcResultData.timeTakenText = `${timeTakenFull}`;
      }

      this.calcReAttemptResultData = calcResultData;
    },
  },

  mounted() {
    this.calculateData();
    if (this.reAttempt) {
      this.calculateReAttemptData();
    }

    this.loading = false;
  },
};
</script>