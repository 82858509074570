<template>
  <v-card id="toppersList" class="py-4" :loading="superLoading || superLoading">
    <v-card-title class="d-flex align-center">
      <v-icon class="mr-2" color="amber darken-2"> mdi-trophy </v-icon>

      Top Rankers :
    </v-card-title>

    <v-card-text>
      <v-container
        fluid
        v-if="!loading || !superLoading || topFiveResults.length > 0"
      >
        <v-row align="center" justify="center">
          <v-list-item
            v-for="(resultObj, i) in topFiveResults"
            :key="i"
            class="py-2"
          >
            <v-list-item-avatar size="30">
              <v-icon color="amber darken-2" size="30">
                {{ `mdi-numeric-${i + 1}-circle` }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-center text-md-left">
              <v-list-item-title class="text-transform-capitalize">{{
                resultObj.studentData.name
              }}</v-list-item-title>

              <v-list-item-subtitle>
                Score: {{ resultObj.score }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ToppersList",
  props: ["superLoading", "topFiveResults"],
  data: () => ({
    loading: false,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
  },

  mounted() {
    this.loading = false;
  },
};
</script>