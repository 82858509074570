<template>
  <v-card
    id="sectionalPerformanceChartCard"
    :loading="superLoading || superLoading"
  >
    <v-card-title>
      <v-icon class="mr-2" color="deep-purple darken-4"> mdi-chart-box </v-icon>

      <span class="mx-auto mx-md-1"> Sectional Skill Chart: </span>

      <!-- Result Toggle Button -->
      <span v-if="reAttempt" class="mx-auto mx-md-1 mt-3 mt-md-0">
        <v-btn-toggle v-model="resultView" class="ml-3" mandatory>
          <v-btn value="original" small> Original </v-btn>

          <v-btn value="reAttempt" small> Re-attempt </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>

    <v-card-text class="px-0">
      <v-container fluid v-if="!loading || !superLoading">
        <v-row class="pa-0" justify="start">
          <v-col v-if="resultView == 'original'" cols="12" sm="12">
            <div v-if="chartData">
              <LineChart
                :chartData="chartData"
                :options="chartOptions"
                class="line-chart"
              />
            </div>
          </v-col>

          <v-col v-else cols="12" sm="12">
            ReAttempt
            <div v-if="reAttemptChartData">
              <LineChartTwo
                :chartData="reAttemptChartData"
                :options="chartOptions"
                class="line-chart"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from "@/components/Home/Result/LineChart.vue";
import LineChartTwo from "@/components/Home/Result/LineChartTwo.vue";

export default {
  name: "SectionalPerformanceChartCard",
  props: [
    "superLoading",
    "testObj",
    "resultObj",
    "allQuestions",
    "reAttempt",
    "reAttemptResultObj",
  ],
  components: { LineChart, LineChartTwo },

  data: () => ({
    loading: false,
    resultView: "original",
    sectionalData: null,
    reAttemptSectionalData: null,
    chartValue: [],
    reAttemptChartValue: [],
    chartSections: [],
    chartData: null,
    reAttemptChartData: null,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    calculateOriginalData() {
      var sectionalData = {};
      var chartValue = [];
      var chartSections = [];

      var chartData = {
        labels: ["Dummy", "Dummy 2"],
        datasets: [
          {
            label: "Accuracy",
            data: [40, 20],
          },
        ],
      };

      //loop through selected questions of test
      Object.keys(this.testObj.selectedQuestions).forEach((section) => {
        //create section obj inside sectionalData
        sectionalData[section] = {
          attempted: 0,
          correct: 0,
          accuracy: 0,
        };

        var sectionQuestionsArr = this.testObj.selectedQuestions[section];
        sectionQuestionsArr.forEach((questionID) => {
          // evaluate score and attempted iff question is answered
          if (
            questionID in this.resultObj.selectedAnswers &&
            this.resultObj.questionState[questionID] == "answered"
          ) {
            sectionalData[section].attempted++;
            if (
              this.resultObj.selectedAnswers[questionID] ==
              this.allQuestions[questionID].correctAnswer
            ) {
              sectionalData[section].correct++;
            }
          }
        });

        // update accuracy for the section
        var accuracy = (
          (sectionalData[section].correct / sectionalData[section].attempted) *
          100
        ).toFixed(2);

        sectionalData[section].accuracy = isNaN(accuracy) ? "0" : accuracy;

        chartSections.push(section);
        chartValue.push(isNaN(accuracy) ? 0 : parseFloat(accuracy));
      });

      this.sectionalData = sectionalData;
      this.chartValue = chartValue;
      this.chartSections = chartSections;

      chartData.labels = chartSections;
      chartData.datasets[0].data = chartValue;

      this.chartData = chartData;
    },

    calculateReAttemptData() {
      var sectionalData = {};
      var chartValue = [];

      var reAttemptChartData = {
        labels: ["Dummy", "Dummy 2"],
        datasets: [
          {
            label: "Accuracy",
            data: [40, 20],
          },
        ],
      };

      //loop through selected questions of test
      Object.keys(this.testObj.selectedQuestions).forEach((section) => {
        //create section obj inside sectionalData
        sectionalData[section] = {
          attempted: 0,
          correct: 0,
          accuracy: 0,
        };

        var sectionQuestionsArr = this.testObj.selectedQuestions[section];
        sectionQuestionsArr.forEach((questionID) => {
          // evaluate score and attempted iff question is answered
          if (
            questionID in this.reAttemptResultObj.selectedAnswers &&
            this.reAttemptResultObj.questionState[questionID] == "answered"
          ) {
            sectionalData[section].attempted++;
            if (
              this.reAttemptResultObj.selectedAnswers[questionID] ==
              this.allQuestions[questionID].correctAnswer
            ) {
              sectionalData[section].correct++;
            }
          }
        });

        // update accuracy for the section
        var accuracy = (
          (sectionalData[section].correct / sectionalData[section].attempted) *
          100
        ).toFixed(2);

        sectionalData[section].accuracy = isNaN(accuracy) ? "0" : accuracy;

        chartValue.push(isNaN(accuracy) ? 0 : parseFloat(accuracy));
      });

      this.reAttemptSectionalData = sectionalData;
      this.reAttemptChartValue = chartValue;

      reAttemptChartData.labels = this.chartSections;
      reAttemptChartData.datasets[0].data = chartValue;

      this.reAttemptChartData = reAttemptChartData;
    },
  },

  mounted() {
    this.calculateOriginalData();

    if (this.reAttempt) {
      this.calculateReAttemptData();
    }

    this.loading = false;
  },
};
</script>