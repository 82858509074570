<template>
  <div id="test" class="fill-height indigo lighten-5 align-center">
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    <!-- TODO: put loading, error, data state conditions -->

    <template v-if="loading">
      <h1 class="text-h1 text-center">Loading...</h1>
    </template>

    <template v-else-if="error">
      <v-alert type="error" text prominent>
        <h1 class="text-h1 text-center">{{ error }}</h1>
      </v-alert>
    </template>

    <template
      v-else-if="
        testObjLocal != null &&
        testScoresObjLocal != null &&
        testObjLocal.dataFetched == true
      "
    >
      <v-container>
        <v-row align="start" justify="center">
          <!-- Test Name Card and Re-attempt button -->
          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <v-card>
              <v-card-title class="d-flex justify-center justify-md-start">
                {{ testObjLocal.testName }}

                <v-icon
                  v-if="testObjLocal.isPremium"
                  class="ml-2"
                  color="amber darken-2"
                  >mdi-crown</v-icon
                >

                <v-btn
                  v-if="testObjLocal.isPremium"
                  :to="
                    testObjLocal.isPremium && !isUserSubscribed
                      ? '/premium-pass'
                      : {
                          name: 'Test',
                          params: {
                            id: testObjLocal.id,
                            testObj: testObjLocal,
                            reAttempt: true,
                          },
                        }
                  "
                  color="primary"
                  class="mt-2 mt-md-0 ml-0 ml-md-3"
                  outlined
                  rounded
                  small
                  >Re-attempt</v-btn
                >
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <OverallPerformanceCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :testScoresObj="testScoresObjLocal"
              :userData="userData"
              :reAttempt="reAttempt"
              :reAttemptResultObj="reAttemptResultObj"
            />
          </v-col>

          <v-col
            v-if="testObjLocal.sections.length > 1"
            cols="12"
            sm="12"
            class="px-0 px-md-3"
          >
            <SectionalPerformanceChartCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :reAttempt="reAttempt"
              :reAttemptResultObj="reAttemptResultObj"
            />
          </v-col>

          <v-col cols="12" sm="12" md="8" class="px-0 px-md-3">
            <YouVsTopperCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :topperResultObj="topFiveResults[0]"
              :isTopper="
                topFiveResults[0].studentUID == resultObjLocal.studentUID
              "
              :reAttempt="reAttempt"
              :reAttemptResultObj="reAttemptResultObj"
            />
          </v-col>

          <v-col cols="12" sm="12" md="4" class="px-0 px-md-1">
            <ToppersList
              :superLoading="loading"
              :topFiveResults="topFiveResults"
            />
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <SectionalPerformanceCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :isUserSubscribed="isUserSubscribed"
              :reAttempt="reAttempt"
              :reAttemptResultObj="reAttemptResultObj"
            />
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <SolutionCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);

import OverallPerformanceCard from "@/components/Home/Result/OverallPerformanceCard.vue";
import YouVsTopperCard from "@/components/Home/Result/YouVsTopperCard.vue";
import ToppersList from "@/components/Home/Result/ToppersList.vue";
import SectionalPerformanceCard from "@/components/Home/Result/SectionalPerformanceCard.vue";
import SectionalPerformanceChartCard from "@/components/Home/Result/SectionalPerformanceChartCard.vue";
import SolutionCard from "@/components/Home/Result/SolutionCard.vue";

export default {
  name: "Result",

  metaInfo: {
    title: "Result",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Result Page description lorem ipsum dolor sit amet.",
      },
    ],
  },

  props: ["testObj", "resultObj", "allQuestions"],

  components: {
    OverallPerformanceCard,
    SectionalPerformanceCard,
    SolutionCard,
    YouVsTopperCard,
    ToppersList,
    SectionalPerformanceChartCard,
  },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    userData() {
      return this.$store.getters.userData;
    },

    isUserSubscribed() {
      const userDataObj = this.userData;
      if (userDataObj && "subscribedTill" in userDataObj) {
        const userSubscribedTill = dayjs(
          userDataObj.subscribedTill.seconds * 1000
        );
        return dayjs().isSameOrBefore(userSubscribedTill);
      }
      return false;
    },
  },

  data: () => ({
    // Page State
    loading: true,
    discount: false,
    snackbar: false,
    snackbarText: "",
    error: "",
    currentWindow: "",
    instructionsLang: "en",
    testLang: "",
    testObjLocal: null,
    resultObjLocal: null,
    allQuestionsLocal: null, //all test questions with key id
    testScoresObjLocal: null,
    topFiveResults: [],
    reAttempt: false,
    reAttemptResultObj: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },

    setError(val) {
      this.loading = false;
      this.error = val;
    },

    setCurrentWindow(val) {
      this.currentWindow = val;
    },

    setTestLang(val) {
      this.testLang = val;
    },

    // also re-routes if user is not subbed and test is premium
    fetchResultData(testID) {
      this.$store
        .dispatch("getResultData", testID)
        .then((testDataRes) => {
          //  testDataRes= {questionsData, testObj, resultObj, testScoresObj, noAttempt: bool, topFiveResults}

          if (testDataRes.noAttempt) {
            this.$router.push("/404");
          }

          this.testObjLocal = testDataRes.testObj;
          this.resultObjLocal = testDataRes.resultObj;
          this.allQuestionsLocal = testDataRes.questionsData.questionsDataObj;
          this.testScoresObjLocal = testDataRes.testScoresObj;
          this.topFiveResults = testDataRes.topFiveResults;

          //replace folders in testObjLocal.selectedQuestions with array of questions
          //(or in other words: make it like manually selected Questions)
          //for the respective folder
          Object.keys(this.testObjLocal.selectedQuestions).forEach(
            (section) => {
              //check if it is not an array: then it is an obj {folderName: ''}
              if (
                this.testObjLocal.selectedQuestions[section].constructor !==
                Array
              ) {
                let folderName =
                  this.testObjLocal.selectedQuestions[section].folderName;

                this.$set(
                  this.testObjLocal.selectedQuestions,
                  section,
                  testDataRes.questionsData.folderQuestions[folderName]
                );
              }
            }
          );

          this.testObjLocal.dataFetched = true;
          this.setLoading(false);
        })
        .catch((error) => {
          this.error = error;
          this.setLoading(false);
        });
    },
  },

  mounted() {
    const testID = this.$route.params.id;

    // check valid test id
    if (isNaN(testID) || testID.toString().length !== 10) {
      this.$router.push("/404");
      return;
    }

    const testObj = this.testObj;
    const resultObj = this.resultObj;
    const allQuestionsObj = this.allQuestions;

    //check if props exists
    if (
      typeof testObj === "object" &&
      testObj !== null &&
      typeof resultObj === "object" &&
      resultObj !== null &&
      typeof allQuestionsObj === "object" &&
      allQuestionsObj !== null
    ) {
      // if (testObj.isPremium && !this.isUserSubscribed) {
      //   this.$router.push("/premium-pass");
      // }

      if ("reAttempt" in resultObj && resultObj.reAttempt) {
        this.reAttempt = true;
      }

      //test obj exists; only fetch testScores and topResults doc from server
      this.$store
        .dispatch("getTestScoresDoc", testID)
        .then((testScoresObj) => {
          this.$store
            .dispatch("getTopFiveResults", testID)
            .then((topFiveResults) => {
              this.topFiveResults = topFiveResults;
              if (this.reAttempt) {
                this.$store
                  .dispatch("getResultObj", testID)
                  .then((originalResultObj) => {
                    this.resultObjLocal = originalResultObj[0];

                    this.testScoresObjLocal = testScoresObj;
                    this.testObjLocal = testObj;

                    this.reAttemptResultObj = resultObj; // result recieved in props is reAttempt result

                    this.allQuestionsLocal = allQuestionsObj;
                    this.testObjLocal.dataFetched = true;

                    this.loading = false;
                  })
                  .catch((err) => {
                    this.error = err;
                    this.loading = false;
                  });
              } else {
                this.resultObjLocal = resultObj;
                this.testScoresObjLocal = testScoresObj;
                this.testObjLocal = testObj;
                this.allQuestionsLocal = allQuestionsObj;
                this.testObjLocal.dataFetched = true;
                this.loading = false;
              }
            })
            .catch((err) => {
              this.error = err;
              this.loading = false;
            });
        })
        .catch((err) => {
          this.error = err;
          this.loading = false;
        });
    } else {
      // no valid testObj found: fetch from server
      this.fetchResultData(testID.toString());
    }
  },
};
</script>

<style>
.htmlFromEditor img {
  max-width: 100% !important;
  height: auto;
}
</style>